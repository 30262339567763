<template>
  <v-footer
    id="home-footer"
    color="#646669"
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class="text-center white--text"
          cols="12"
          md="12"
        >
          Copyright &copy; 2023~2024 RONGHAO ROBOT TECHNOLOGY
          <br>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
